<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  تعديل  موعد">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >

            <b-row>
              <!-- <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="employe"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> الموظف</label>
                    <v-select
                      v-model="employe"
                      :options="optionemploye"
                      :reduce="(val) => val.value"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col> -->
              <b-col md="3" xl="3">
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="person"
                  :state="errors.length > 0 ? false : null"
                >
             
                <label> الفرد غير المسجل</label>
                <v-select
                    v-model="un_registered_people_id"
                    :reduce="(val) => val.value"
                    :options="optionUnRegisteredPerson"
                    :filterable="true"   
                    :searchable="true"
                    placeholder="search"
                  />
              </b-form-group>
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                 
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ البداية </label>
                    <flat-pickr
                      v-model="start_time"
                      class="form-control"
                      placeholder="حدد تاريخ "
                      :config="{

                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',

                      }"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ النهاية </label>
                    <flat-pickr
                      v-model="finish_time"
                      class="form-control"
                      :config="{

                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',

                      }"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules=""
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label> ملاحظة</label>
                    <b-form-input
                      id="input-default"
                      v-model="notes"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="updateappointment"
                >

                  <span class="align-middle"> تعديل </span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col
      md="4"
      xl="2"
    >
      <b-button
        variant="purple "
        @click="$router.go(-1)"
      >
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: '',

  components: {
    flatPickr,
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
      optionUnRegisteredPerson:[],
      optionerewardType: [{
        label: 'عينية',
        value: 1,
      },

      {
        label: 'مادية',
        value: 0,
      },
      ],

      title: '',
      amount: '',
      rewardType: '',
      employe: '',
      date: '',
      notes: '',
      start_time: '',
      optionemploye: [],
      finish_time: '',
    }
  },

  created() {
    this.getappointment()
    this.getunregister()
  },

  methods: {
    getunregister() {
      this.optionUnRegisteredPerson = []
      // this.$refs.childComponent.hide()
      this.$http.get('/api/v1/mobile-team/un-registered-persons').then(res => {
       console.log(res.data);
        res.data.data.forEach(el => {
          this.optionUnRegisteredPerson.push({ value: el.id, label: `${el.first_name} ${el.father_name} ${el.last_name} , ${el.mother_name} ` })
        })
        //
      })
    },
    getappointment() {
      this.options = []
      this.$http.get(`/api/v1/appointment/${this.$route.params.id}`).then(res => {
        // console.log(res.data.data);
        this.start_time = res.data.data.start_time
        this.un_registered_people_id = res.data.data.un_registered_people_id
        this.finish_time = res.data.data.finish_time
        this.notes = res.data.data.notes
      })
    },

    updateappointment() {
      const data = {
        start_time: this.start_time,
        un_registered_people_id: this.un_registered_people_id,
        finish_time: this.finish_time,
        notes: this.notes,
      }
      const url = `/api/v1/appointment/${this.$route.params.id}`
      this.$http.put(url, data).then(res => {
        // console.log(res);
        this.$swal({
          title: 'تم التعديل بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }).catch(error => {
        this.$swal({
          text: `${error.response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    //
  },
}
</script>

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>
